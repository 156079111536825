.department-details-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.image-section {
  position: relative;
  width: 100%;
  height: 30vh; /* 30% of viewport height */
  overflow: hidden; /* Hide any overflow */
}

.department-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the section */
  opacity: 0.6; /* Adjust opacity here */
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(9, 37, 164);
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(14, 86, 152, 0.7); /* Optional: adds a shadow for better readability */
  z-index: 10; /* Ensure the text is above the image */
}

.department-details-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
}

.department-details-paper {
  width: 100%;
  max-width: 1200px; /* Maximum width on large screens */
  padding: 20px;
  background-color: white;
  border-radius: 8px; /* Rounded corners for the Paper component */
}

/* Tab and Table Styles */
.MuiTabs-root {
  width: 100%; /* Ensure tabs take full width */
}

.MuiTab-root {
  flex: 1; /* Ensure tabs distribute evenly */
}

.MuiTabPanel-root {
  width: 100%; /* Ensure TabPanel takes full width */
}

.table-container {
  margin-top: 20px; /* Margin above the table */
  width: 100%;
}

.table {
  width: 100%;
}

.table-cell {
  padding: 8px; /* Adjust padding in table cells */
}

.table-head-cell {
  background-color: #f5f5f5; /* Light gray background for header cells */
  font-weight: bold;
}

.table-row:nth-of-type(odd) .table-cell {
  background-color: #ffffff; /* White background for odd rows */
}

.table-row:nth-of-type(even) .table-cell {
  background-color: #e3f2fd; /* Light blue background for even rows */
}

@media (max-width: 768px) {
  .department-details-content {
    padding: 10px; /* Less padding on smaller screens */
  }

  .department-details-paper {
    max-width: 100%; /* Full width on smaller screens */
  }

  .MuiTabs-root {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .MuiTab-root {
    padding: 10px; /* Adjust padding for tabs on smaller screens */
  }

  .image-section {
    height: 40vh; /* Adjust height for smaller screens */
  }

  .overlay-text {
    font-size: 18px; /* Adjust font size for smaller screens */
  }
}
