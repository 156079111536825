.singel-course {
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    margin: 15px;
    background: #fff;
    flex: 1 0 30%; /* Adjust this to control the number of items per row */
}

.course-title{
    text-decoration: none;
}
.course-department{
    text-decoration: none;
}
.singel-course .thum {
    position: relative;
}

.singel-course .image img {
    width: 400px;
    height: auto;
}

.singel-course .price {
    position: absolute;
    top: 10px;
    left: 10px;
    background: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 14px;
}

.singel-course .cont {
    padding: 15px;
}

.singel-course .cont ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

.singel-course .cont ul li {
    color: #f39c12;
    margin-right: 5px;
}

.singel-course .cont h5 {
    margin: 10px 0;
    font-size: 13px;
}

.singel-course .course-teacher .name h6 {
    margin: 0;
    font-size: 15px;
    color: #333;
}

/* Style adjustments for mobile view */
@media (max-width: 768px) {
    .singel-course {
        margin: 10px;
        flex: 1 0 45%; /* Adjust this to control the number of items per row on smaller screens */
    }

    .singel-course .image {
        height: 200px;
    }

    .singel-course .cont h5 {
        font-size: 16px;
    }

    .singel-course .course-teacher .name h6 {
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .singel-course {
        flex: 1 0 100%; /* Ensure one item per row */
    }

    .d-flex {
        flex-direction: column; /* Stack items vertically */
    }

    .d-flex.justify-content-around {
        justify-content: center; /* Center items in the container */
    }
}
