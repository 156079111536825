/* General styling */
.feedback-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh; /* Adjust height to keep tabs centered vertically */
  }
  
  .tabs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px; /* Space between the tabs */
    flex-wrap: wrap; /* Wrap tabs on smaller screens */
  }
  
  /* Tab item */
  .tab {
    color: #FFF;
    padding: 12px 20px;
    background-color: #01366b;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    min-width: 150px; /* Ensure each tab has a minimum width */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .tab.active {
    background-color: #007bff;
    color: white;
    border: none;
  }
  
  .tab:hover {
    background-color: #30cfd0;
    color: black;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .tabs-container {
      flex-direction: column; /* Stack tabs vertically on small screens */
      gap: 10px;
    }
  
    .tab {
      width: 100%; /* Full-width tabs on mobile */
    }
  }
  