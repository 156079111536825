@media (max-width: 767px) {
  .home {
    padding-top: 280px; /* Adjust padding based on the height of the header on smaller screens */
  }
}

@media (max-width: 320px) {
  .home {
    padding-top: 260px; /* Adjust padding based on the height of the header on smaller screens */
  }
}
