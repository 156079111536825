.faculty-profile-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    display: flex;
    flex: 1;
    padding: 20px;
  }
  
  .departments-list {
    flex: 1;
    margin-right: 20px;
  }
  
  .faculty-details {
    flex: 3;
  }
  
  .selected-department {
    background-color: #c8e6c9; /* Customize highlight color */
  }
  
  @media (max-width: 768px) {
    .content {
      flex-direction: column;
    }
  
    .departments-list {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  