/* General layout */
.main-container {
  display: grid;
  grid-template-columns: 1fr 3fr; /* Left column for tabs, right column for content */
  gap: 20px;
  margin: 20px;
}

.tabs-section {
  display: flex;
  flex-direction: column;
}

.tabs-section button {
  padding: 15px;
  margin: 10px 0;
  background-color: #f1f1f1;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
  color: #333;
}

.tabs-section button.active {
  background-color: #c2e9fb;
  color: #000;
  font-size: 20px;
  font-weight: 400;
}

.content-section {
  text-align: left;
  overflow: hidden; /* Ensure overflow hidden for content that doesn't require scrolling */
}

.tab-content {
  margin-left: 20px;
}

.skills-list {
  list-style: none;
  padding-left: 0;
}

.skills-list li {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.skills-list i {
  color: #007bff; /* Blue color for the icon */
  margin-right: 10px;
}

/* Image gallery styles */
.image-gallery {
  display: flex;
  overflow-x: scroll; /* Allows horizontal scrolling */
  margin-top: 20px;
  gap: 10px;
  scroll-behavior: smooth; /* Adds smooth scrolling */
}

.gallery-image {
  width: 300px; /* Fixed width for all images */
  height: 200px; /* Fixed height for all images */
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
  border-radius: 10px; /* Optional: Adds rounded corners */
  flex-shrink: 0; /* Prevent shrinking */
}

.image-gallery::-webkit-scrollbar {
  height: 8px; /* Custom scrollbar height */
}

.image-gallery::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 10px; /* Rounded scrollbar */
}

.image-gallery::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker scrollbar on hover */
}

/* Responsive design */
@media (max-width: 768px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }

  .tabs-section {
    flex-direction: row;
    overflow-x: auto; /* Allows tabs to scroll horizontally on mobile */
  }

  .tabs-section button {
    flex: 1;
    text-align: center;
  }

  .content-section {
    margin-top: 20px;
  }
}
