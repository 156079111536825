/* src/components/stats/Stats.css */

.stats-section {
  position: relative;
  background-image: url("../../../assests/images/background-2.jpg");
  background-size: cover; /* Ensure the image covers the entire section */
  background-position: center; /* Center the image */
  padding: 50px 0 20px 0;
  color: #034da1;
  text-align: center;
  overflow: hidden;
}

.stats-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(77, 135, 205, 0.352); /* Black overlay with 50% opacity */
  z-index: 1;
}

.stats-section > * {
  position: relative;
  z-index: 2;
}

.stat-item {
  margin-bottom: 30px;
}

.stat-item .icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.stat-icon {
  color: #fff; /* White color for the icon */
  background: #007bff; /* Primary color */
  border-radius: 50%;
  padding: 15px; /* Increased padding for larger icon */
  font-size: 2rem; /* Larger icon size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adding shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Increased shadow on hover */
}

.stat-item h3 {
  margin-top: 10px;
  font-size: 2rem;
  color: #fff; /* White color for the count */
}

.stat-item p {
  margin-top: 5px;
  font-size: 1.2rem;
  color: #094dcb;
  font-weight: 500; /* White color for the text */
}

.count-box {
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
  padding: 20px;
  margin: 0 auto; /* Center align the box */
  box-sizing: border-box;
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .count-box {
    padding: 15px;
    max-width: 100%; /* Full width on smaller screens */
  }
  .stat-item {
    margin-bottom: 20px;
  }

  .stat-item h3 {
    font-size: 1.5rem;
  }

  .stat-item p {
    font-size: 1rem;
  }
}
