.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px; /* Add padding to ensure content is not too close to edges */
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .section-title h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .single-course {
    border: 1px solid #ddd;
    padding: 20px;
    background: #f9f9f9; /* Light background color */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
    transition: transform 0.3s ease-in-out;
    margin: 0 10px; /* Gap between courses */
  }
  
  .single-course:hover {
    transform: translateY(-10px);
  }
  
  .course-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%; /* Ensure content is centered */
  }
  
  .course-img {
    margin-bottom: 15px; /* Spacing between image and text */
  }

  .course-img img {
    width: 100%; /* Ensure image fits within its container */
    height: 200px; /* Set a fixed height for images */
    object-fit: cover; /* Ensure image maintains aspect ratio */
    border-radius: 8px; /* Rounded corners for the image */
  }
  
  .text-center {
    text-align: center;
  }
  .text-center a {
    text-decoration: none;
    font-size: 15px;
  }
  
  .slick-prev, .slick-next {
    font-size: 20px;
    color: #333;
  }
  
  .slick-prev:hover, .slick-next:hover {
    color: #01366b;
  }
  
  /* Adjust the gap between items */
  .slick-slide {
    padding: 0 15px; /* Space between slides */
  }
  
  /* Dots styles */
  .slick-dots {
    bottom: -30px; /* Adjust dots position */
  }
  