.about-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

.about-content,
.about-image,
.about-news-events {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.about-content {
  background-color: #f9f9f9;
  text-align: justify;
}

.about-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}

.about-content p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.read-more-btn {
  background-color: #01366b;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: auto;
  cursor: pointer;
}

.read-more-btn:hover {
  background-color: #011d4b;
}

.about-image {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about-image img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-news-events {
  border: 1px solid #ffb606;
}

.news-events-header {
  background: #ffb606;
  padding: 10px;
}

.news-events-header h1 {
  margin: 0;
}

.news-events-content {
  padding: 10px;
  overflow: hidden;
  height: 300px; /* Adjust height as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.news-items-wrapper {
  animation: scrollUp 10s linear infinite;
  width: 100%; /* Ensures wrapper takes the full width */
  box-sizing: border-box;
}

.news-item {
  margin-bottom: 10px;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: contain; /* Ensures the image fits within the container without cropping */
}

.eventdt {
  font-weight: bold;
}

.eventdtls {
  margin: 0;
}

@keyframes scrollUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
  }

  .about-content,
  .about-image,
  .about-news-events {
    padding: 10px;
    margin-bottom: 20px;
  }

  .read-more-btn {
    width: 100%;
    text-align: center;
  }
}
