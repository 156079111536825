/* Central Box Styling */
.central-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Adjust height according to your preference */
    margin: 20px 0;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 80%; /* Adjust width as necessary */
    max-width: 600px; /* Limit the maximum width */
    padding: 20px;
    background-color: #f4f4f4; /* Background color for the central box */
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for better look */
  }
  
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e0;
    padding: 20px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    text-decoration: none;
    color: #333;
  }
  
  .grid-item:hover {
    background-color: #01366b;
    color: #FFF;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr;
      width: 90%;
    }
  }