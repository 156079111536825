/* Infrastructure.css */
.infrastructure-wrapper {
    padding: 16px; /* Add padding around the wrapper */
  }
  
  .infrastructure-heading {
    text-align: center;
    font-size: 24px; /* Adjust font size as needed */
    font-weight: bold;
    margin-bottom: 16px; /* Space between heading and grid */
    color: #333; /* Adjust text color as needed */
  }
  
  .infrastructure-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    padding: 0; /* Remove padding as it is added in the wrapper */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  .image-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 200px; /* Set a fixed height for the box */
    margin: 0 8px; /* Add horizontal margin to create space on left and right */
  }
  
  .image-item:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
  }
  
  .image-item img {
    width: 100%;
    height: 100%; /* Set the height of the image relative to the container */
    object-fit: cover; /* Ensure image covers its area */
    display: block;
  }
  
  .image-heading {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    padding: 8px;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box; /* Ensure padding is included in height */
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .infrastructure-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .infrastructure-container {
      grid-template-columns: 1fr;
    }
  }
  