.antiragging-container {
    padding: 25px 0 0 0;
  }
  .table-container {
    padding: 16px;
  }
  
  .table-container h2 {
    text-align: center;
    margin-bottom: 16px;
  }
  
  .table-container .MuiTableContainer-root {
    margin: 0 auto; /* Center the table */
  }
  
  .MuiTableHead-root {
    background-color: #01366b; /* Set the header background color */
  }
  
  .MuiTableCell-head {
    color: white; /* Set the header text color */
  }
  
  .pdf-link {
    text-align: center;
    margin-top: 16px;
  }
  
  @media (min-width: 960px) {
    .table-container .MuiTableContainer-root {
      width: 70%; /* Adjust this value as needed */
    }
  }
  
  @media (max-width: 600px) {
    .table-container {
      padding: 8px;
    }
  
    .MuiTableCell-root {
      padding: 8px;
      font-size: 0.875rem;
    }
  
    .MuiTableCell-head {
      font-size: 0.875rem;
    }
  }
  