.footer {
  background-color: #01366b;
  color: aliceblue;
  padding: 20px;
  text-align: center;
  font-size: 15px;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-section {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.footer-section h4 {
  margin-top: 0;
  text-align: left;
}

.footer-section p,
.footer-section ul {
  margin: 0;
  padding: 0;
  text-align: left;
}

.footer-section ul {
  list-style-type: none;
}

.footer-section ul li {
  margin: 5px 0;
}

.footer-section ul li a {
  color: aliceblue;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.feedback-section {
  text-align: left;
}

/* Center Quick Links */
.footer-section.quick-links {
  text-align: center;
}

.map-section .map-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.map-section .map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.footer-copyright {
  background-color: #011d4b;
  padding: 10px;
  width: 100%;
  text-align: center;
}

.footer-copyright p {
  margin: 0;
}

.footer-copyright a {
  text-decoration: none;
  color: aliceblue;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    min-width: 100%;
    margin-bottom: 20px;
  }

  .footer-section h4,
  .footer-section p,
  .footer-section ul {
    text-align: center;
  }
}
