.department-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-section {
    position: relative;
  }
  
  .department-image {
    width: 100%;
    height: auto;
    padding-top: 20px;
  }
  
 
  
  .gallery-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .gallery-menu button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .gallery-menu button:hover {
    background-color: #0056b3;
  }
  
  .gallery-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .gallery-image {
    width: calc(33.33% - 20px);
    margin: 10px;
    height: auto;
    border-radius: 5px;
  }
  
  @media (max-width: 768px) {
    .gallery-image {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .gallery-image {
      width: calc(100% - 20px);
    }
  }
  