/* Contact.css */
.contact-container {
  margin-top: 2rem;
}

.contact-box {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 400px; /* Ensure boxes have a minimum height */
}

.contact-details, .contact-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form .MuiTextField-root {
  margin-bottom: 1rem;
}

.contact-form .MuiFormControl-root {
  margin-bottom: 1rem;
}

/* Media query for mobile responsiveness */
@media (max-width: 600px) {
  .contact-form .MuiFormControl-root,
  .contact-form .MuiTextField-root {
    width: 100%;
  }

  .MuiSelect-root {
    width: 100%;
  }

  .MuiPaper-root {
    max-width: 100% !important;
    width: auto !important;
  }

  .MuiMenuItem-root {
    font-size: 0.875rem; /* Set smaller font size for menu items in mobile view */
  }
}