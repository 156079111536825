/* Navbar styling */
.navbar {
  width: 100%;
  background-color: #01366b;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: center; /* Align items to the center horizontally */
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* Rest of the existing styles remain unchanged */
.navbar .hamburger {
  display: none;
  font-size: 28px;
  cursor: pointer;
}

/* Navbar links */
.navbar .nav-links {
  list-style: none;
  display: flex;
  justify-content: center; /* Center the items in the nav-links */
  align-items: center;
  margin: 0 auto; /* This centers the nav-links within the navbar */
  padding: 0;
  max-width: 1200px; /* You can set a max-width as per your requirement */
  width: 100%;
}

.navbar .nav-links li {
  margin: 0 10px;
  position: relative;
}

.navbar .nav-links a {
  color: white;
  text-decoration: none;
  padding: 8px 14px;
  display: block;
  text-align: center;
}

/* Hover effects for navbar items */
.navbar .nav-links a:hover,
.navbar .nav-links li:hover > a {
  background-color: #c2e9fb;
  color: #000;
  border-radius: 4px;
}

/* Dropdown styling */
.navbar .dropdown-content,
.navbar .dropdown-subcontent {
  display: none;
  position: absolute;
  background-color: #01366b;
  z-index: 1;
  margin-top: 8px;
  padding: 0;
  list-style: none; /* Remove bullet points */
  width: max-content; /* Ensure dropdown width is only as wide as needed */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.navbar .dropdown-content {
  min-width: 160px;
}

.navbar .dropdown-subcontent {
  min-width: 160px;
  left: 100%; /* Position to the right of the parent dropdown */
  top: 0;
}

.navbar .dropdown-content li,
.navbar .dropdown-subcontent li {
  margin: 0;
}

.navbar .dropdown-content a,
.navbar .dropdown-subcontent a {
  padding: 8px 16px;
  color: white;
  display: block;
  text-align: left;
  font-size: 15px;
}

/* Hover effects for dropdown items */
.navbar .dropdown-content a:hover,
.navbar .dropdown-subcontent a:hover {
  background-color: #555;
  border-radius: 4px;
}

/* Dropdown hover */
.navbar .dropdown:hover .dropdown-content,
.navbar .dropdown .open {
  display: block;
}

.navbar .dropdown .dropdown-item:hover .dropdown-subcontent {
  display: block;
}

/* Hamburger menu (for mobile) */
@media (max-width: 768px) {
  .navbar .hamburger {
    display: block;
  }

  .navbar .nav-links {
    display: none;
    flex-direction: column; /* Stack items vertically */
    width: 100%; /* Full width on mobile */
    background-color: #01366b;
    position: absolute;
    top: 60px; /* Adjust position below the navbar */
    left: 0;
    z-index: 1000;
  }

  .navbar .nav-links.open {
    display: flex;
  }

  .navbar .nav-links li {
    margin: 0;
    width: 100%;
    text-align: center;
  }

  .navbar .nav-links li a {
    padding: 15px 0;
    border-top: 1px solid #444;
  }

  .navbar .dropdown-content,
  .navbar .dropdown-subcontent {
    position: relative;
    width: 100%;
    left: 0; /* Ensure dropdown does not overflow to the right */
    box-sizing: border-box;
  }

  .navbar .dropdown-content a,
  .navbar .dropdown-subcontent a {
    padding: 10px;
    text-align: center; /* Center-align text in mobile view */
  }
}
