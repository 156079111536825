.aboutus-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.management,
.vision-mission,
.quality-policy,
.aboutus-video {
  margin-bottom: 20px;
}


ul {
  padding-left: 20px;
}

.responsive-video {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .aboutus-container {
    padding: 10px;
  }
}
