header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure it stays above other components */
  background-color: #ffffff; /* Add a background color to avoid transparency issues */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}
.counselling-code {
  background: linear-gradient(45deg, #01366b, #4fabce, #01366b); /* Gradient colors */
  background-size: 300% 300%; /* Extend the background size for animation */
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
  color: aliceblue;
  animation: gradientAnimation 5s ease infinite; /* Apply only the gradient animation */
  border-radius: 30px; /* Add border radius */
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/* Apply blink animation only to the text */
.counselling-code span {
  animation: blink 1s step-end infinite; /* Blink effect only on the text */
}

/* Keyframes for the blink effect */
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.view-button {
  background-color: #f0f0f0;
  color: black;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

.view-button:hover {
  background-color: #01366b;
  color: white;
}


.header-buttons {
  display: flex;
  gap: 10px;
}


.marquee-container {
  background-color: #30cfd0;
  padding: 10px 0;
  text-align: center;
}

marquee {
  font-size: 16px;
  color: #000;
}


#naac-button,
#nirf-button,
#aicte-button,
#mandatory-disclosure-button {
  background-color: #30cfd0; /* Custom background color */
  border-color: #30cfd0; /* Custom border color */
  color: #01366b;
  font-weight: bold;
}



.header-top {
  background-color: #01366b;
  padding: 10px 20px;
}

.logo {
  text-align: center;
  padding: 10px;
}

.logo img {
  max-width: 100%;
  height: auto;
  max-height: 100px; /* Adjust this value as needed */
}

#naac-button {
  background-color: #30cfd0; /* Custom background color */
  border-color: #30cfd0; /* Custom border color */
  color: #01366b;
  font-weight: bold;
}

.navbar-custom {
  margin-top: 10px;
  background-color: #01366b;
}

/* Center the navbar */
.navbar-custom .navbar-collapse {
  display: flex;
  justify-content: center;
}

/* Ensure navbar items are centered */
.navbar-custom .navbar-nav {
  display: flex;
  justify-content: center;
}

.navbar-custom .nav-link,
.navbar-custom .nav-item {
  margin: 0 5px; /* Adjust the spacing as needed */
}

/* .carousel {
  margin-top: 20px;
} */

.carousel img {
  max-height: 400px;
  object-fit: cover;
}

.navbar-custom .navbar-nav .nav-link {
  color: #ffffff; /* Set text color */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.navbar-custom .navbar-nav .nav-link:hover {
  color: #30cfd0; /* Change text color on hover */
}

.navbar-custom .nav-item:hover .dropdown-menu {
  display: block; /* Show dropdown menu on hover */
}

.dropdown-menu {
  background-color: #01366b;
}

.dropdown-item {
  color: #ffffff; /* Set dropdown text color */
}

.dropdown-item:hover {
  background-color: #30cfd0; /* Change background color on hover */
  color: #01366b; /* Change text color on hover */
}

.contact-info {
  display: flex;
  gap: 450px;
  font-weight: 500;
  color: #FFF;
}

.contact-info span {
  font-size: 20px;
}

/* Position the welcome text over the first image */
.carousel-caption-custom {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
}

.welcome-text {
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  animation: fadeIn 3s ease-in-out;
}

/* Style for buttons */
.carousel-buttons {
  margin-top: 20px;
}

.custom-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #01366b;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.custom-button:hover {
  background-color: #30cfd0;
  color: #01366b;
}

/* Animation for the text */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .welcome-text {
    font-size: 24px; /* Smaller text size for mobile */
  }
  
  .custom-button {
    padding: 8px 16px;
    font-size: 14px;
    margin: 5px;
  }

  .carousel-caption-custom {
    top: 30%; /* Adjust positioning for smaller screens */
  }
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .marquee-container marquee {
    font-size: 14px;
  }
  .header-top {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .header-buttons {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  #naac-button,
  #nirf-button,
  #aicte-button,
  #mandatory-disclosure-button {
    margin-top: 10px;
  }

  .logo {
    text-align: center;
    padding: 10px 0;
  }

  .counselling-code {
    font-size: 14px;
    width: 100%;
    padding: 10px 0;
  }

  .contact-info {
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    gap: 5px;
  }

  .navbar-custom .navbar-collapse {
    justify-content: flex-start;
  }

  .navbar-custom .nav-link,
  .navbar-custom .nav-item {
    margin: 5px 0;
  }

  .dropdown-menu {
    width: 100%; /* Ensure dropdown takes full width on mobile */
    text-align: center; /* Center align dropdown items */
  }

  .dropdown-item {
    padding: 10px; /* Add padding for better touch targets */
  }
}


/* Mobile responsiveness */
@media (max-width: 767px) {
  header {
    position: relative; /* Change position to relative on mobile */
    box-shadow: none; /* Remove shadow on mobile if desired */
  }

  .marquee-container marquee {
    font-size: 14px;
  }

  .header-top {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .header-buttons {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  #naac-button,
  #nirf-button,
  #aicte-button,
  #mandatory-disclosure-button {
    margin-top: 10px;
  }

  .logo {
    text-align: center;
    padding: 10px 0;
  }

  .counselling-code {
    font-size: 14px;
    width: 100%;
    padding: 10px 0;
  }

  .contact-info {
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    gap: 5px;
  }

  .navbar-custom .navbar-collapse {
    justify-content: flex-start;
  }

  .navbar-custom .nav-link,
  .navbar-custom .nav-item {
    margin: 5px 0;
  }

  .dropdown-menu {
    width: 100%; /* Ensure dropdown takes full width on mobile */
    text-align: center; /* Center align dropdown items */
  }

  .dropdown-item {
    padding: 10px; /* Add padding for better touch targets */
  }
}