.placement-container {
    padding: 20px;
  }
  
  .image-section {
    position: relative;
  }
  
  .placement-image {
    width: 100%;
    height: auto;
  }
  
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
  }
  
  .content-section {
    margin-top: 20px;
    text-align: left;
    padding: 10px;
  }
  
  @media (max-width: 768px) {
    .content-section h2 {
      font-size: 1.5rem;
    }
  
    .content-section h3 {
      font-size: 1.2rem;
    }
  
    .content-section p {
      font-size: 1rem;
    }
  
    .overlay-text {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .content-section h2 {
      font-size: 1.2rem;
    }
  
    .content-section h3 {
      font-size: 1rem;
    }
  
    .content-section p {
      font-size: 0.9rem;
    }
  
    .overlay-text {
      font-size: 1rem;
    }
  }
  