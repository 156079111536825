.naac-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  overflow-x: auto; /* Allow horizontal scrolling if needed */
}

.tabs {
  width: 200px;
  margin-right: 20px;
}

.tab-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  background-color: #f1f1f1;
  border: none;
  cursor: pointer;
  text-align: left;
}

.tab-button.active {
  background-color: #01366b;
  color: white;
}

.content-naac {
  flex-grow: 1;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Ensure content can scroll horizontally */
}


.criteria-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: auto; /* Ensures the table adjusts to content */
}

.criteria-table th, .criteria-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  word-wrap: break-word;
}

.criteria-table th {
  background-color: #01366b; /* Custom yellow color */
  color: #FFF;
  font-size: 18px;
  font-weight: 200;
}

.criteria-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.criteria-table a {
  color: #007bff;
  text-decoration: none;
}

.criteria-table a:hover {
  text-decoration: underline;
}

.span-color-heading{
  color: red;
}
.link-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.3rem;
  text-align: left; /* Ensure text aligns to the left */
  width: 100%; /* Make sure the button takes the full width */
  display: inline-block;
}

.link-button:hover {
  text-decoration: underline;
}



/* Mobile Responsive Design */
@media (max-width: 768px) {
  .naac-container {
    flex-direction: column;
    align-items: center;
  }

  .tabs {
    width: 100%;
    margin-bottom: 10px;
  }

  .content-naac {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .tab-button {
    font-size: 14px;
    padding: 8px;
  }

  .content-naac {
    padding: 15px;
  }
}
