/* src/App.css */
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Jost:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f0f0; /* Add this line */
  font-family: "Jost", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  padding-top: 250px;
}

main {
  flex: 1;
}

.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
}

.whatsapp-icon:hover {
  background-color: #12208c;
  transform: scale(1.1); /* Slightly increase size on hover */
}

@media (max-width: 767px) {
  .App {
    padding-top: 0;
  }
}

@media (max-width: 320px) {
  .home {
    padding-top: 500px; /* Adjust padding based on the height of the header on smaller screens */
  }
}
