.pdf-link-list-group-item {
  cursor: pointer;
  padding: 15px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pdf-link-list-group-item:hover {
  background-color: #e9ecef;
  border-color: #adb5bd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pdf-link-list-group-item a {
  text-decoration: none;
  color: #070707;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.pdf-link-list-group-item a:hover {
  color: #0056b3;
}

.pdf-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #0056b3;
}

.pdf-icon:hover {
  color: #003d7a;
}

@media (max-width: 576px) {
  .pdf-icon {
    font-size: 14px;
  }
}
